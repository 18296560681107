// @flow
import React, { Fragment, } from 'react';
import { useFela, } from 'react-fela';
import { borderBottom, borderTop, } from '@haaretz/htz-css-tools';

import type { ChildrenArray, Node, } from 'react';
import { element, } from 'prop-types';
import type { TabsElementType, TabItemType, } from '../../flowTypes/TabsElementType';
import Query from '../ApolloBoundary/Query';

import Tabs from '../Tabs/Tabs';
import TabList from '../TabList/TabList';
import Tab from '../Tab/Tab';
import TabPanel from '../TabPanel/TabPanel';
import ListViewHeader from '../ListViewHeader/ListViewHeader';
import List from '../List/List';
import ListView from '../ListView/NewListView';
import { isClickTrackerWrapper, isAdSlot, isList, } from '../../utils/validateType';
import ClickTracker from '../ClickTracker/ClickTrackerWrapper';
import AdSlot from '../AdManager/AdSlot';
import Debug from '../Debug/Debug';
import LAZY_TAB_ELEMENT from './lazyTabElement.graphql';

const incByKey = {
  rtl: { ArrowLeft: 1, ArrowRight: -1, },
  ltr: { ArrowLeft: -1, ArrowRight: 1, },
};

const tabRule: Object => Object = ({ theme, }) => ({
  flexBasis: 'auto',
  position: 'relative',
  textAlign: 'center',
  color: theme.color('tabElement', 'tabText'),
});

type TabButtonProps = {
  children: ChildrenArray<Node> | Node,
  isActive: boolean,
};

const tabButtonStyle = ({ theme, isActive, }) => ({
  ...(isActive
    ? {
      backgroundColor: theme.color('tabElement', 'activeTabBg'),
      fontWeight: '700',
      color: theme.color('tabElement', 'activeTabColor'),
    }
    : {}),
  paddingTop: '1rem',
  paddingBottom: '1rem',
  paddingStart: '3rem',
  paddingEnd: '3rem',
  position: 'relative',
  width: '100%',
  ':focus': {
    outline: 'none',
    backgroundColor: theme.color('tabElement', 'activeTabBg'),
    color: theme.color('tabElement', 'activeTabColor'),
  },
  ':focus:after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '2px',
    backgroundColor: theme.color('tabElement', 'focusBorderBottom'),
    bottom: '0',
    right: '0',
    zIndex: 1,
  },
  extend: [
    theme.type(0, { until: 'l', }),
    ...(isActive
      ? [ borderTop('2px', 0, 'solid', theme.color('tabElement', 'activeBorderTop')), ]
      : []),
  ],
});
// $FlowFixMe: forwardRef is missing from React type in current version
export const TabButton = React.forwardRef<TabButtonProps, HTMLButtonElement>(
  // eslint-disable-next-line react/prop-types
  ({ isActive, children, ...props }, ref) => {
    const { css, } = useFela({ isActive, });
    return (
      <button type="button" className={css(tabButtonStyle)} ref={ref} {...props}>
        {children}
      </button>
    );
  }
);

type TabsElementProps = TabsElementType & {
  startAt: number,
};

TabElement.defaultProps = {
  startAt: 1,
  List: null,
};

function TabElement({ elements, startAt, }: TabsElementProps): Node {
  const { theme, } = useFela();
  if (!elements?.length) return null;

  return (
    <Tabs
      activeTab={startAt}
      miscStyles={{
        ...theme.mq(
          { from: 's', },
          {
            backgroundColor: theme.color('tabElement', 'tabsBg'),
            paddingInlineStart: '2rem',
            paddingInlineEnd: '2rem',
          }
        ),
      }}
    >
      {({ setActiveTab, activeTab, }) => {
        const activeElement: TabItemType = elements[activeTab] || elements[0];
        const tabRefs = elements.map(_ => React.createRef());

        return (
          <Fragment>
            <TabList
              activeTab={activeTab}
              onKeyDown={({ key, }) => {
                const inc = incByKey[theme.direction || 'rtl'][key];
                if (!inc) return;
                const nextActiveTab = (elements.length + activeTab + inc) % elements.length;
                tabRefs[nextActiveTab].current
                  && tabRefs[nextActiveTab].current.focus
                  && tabRefs[nextActiveTab].current.focus();
                setActiveTab(nextActiveTab);
              }}
              miscStyles={{
                color: theme.color('tabElement', 'tabListText'),
                display: 'flex',
                backgroundColor: theme.color('tabElement', 'tabListBg'),
                marginBottom: [ { until: 'l', value: '2px', }, ],
                fontFamily: theme.fontStacks[theme.framedFont],
                ...theme.type(-1),
                ...theme.mq(
                  { from: 'l', },
                  {
                    ...borderBottom(
                      '1px',
                      0,
                      'solid',
                      theme.color('tabElement', 'tabListBorderBottom')
                    ),
                  }
                ),
              }}
            >
              {elements.map((element, i: number) => (
                <Tab
                  key={element.contentId}
                  index={i}
                  isActive={i === activeTab}
                  tabRef={tabRefs[i]}
                  controls={`tab-${element.contentId}`}
                  presentation
                  rule={tabRule}
                  setActiveTab={index => setActiveTab(index)}
                  render={TabButton}
                >
                  {isList(element) ? <span>{element.title}</span> : null}
                </Tab>
              ))}
            </TabList>
            <TabPanel id={`tab-${activeElement.contentId}`}>
              {isClickTrackerWrapper(activeElement) ? (
                <ClickTracker {...activeElement} />
              ) : isAdSlot(activeElement) ? (
                <AdSlot {...activeElement} />
              ) : isList(activeElement) ? (
                <List {...activeElement} isExpanded />
              ) : (
                <Debug>
                  {`Element of type '${activeElement.inputTemplate}' is not supported in TabElement`}
                </Debug>
              )}
            </TabPanel>
          </Fragment>
        );
      }}
    </Tabs>
  );
}

type Props = TabsElementType & {
  withoutWrapper?: boolean,
};

WrappedTabs.defaultProps = {
  withoutWrapper: false,
};

function WrappedTabs({ withoutWrapper, elements, ...props }: Props): Node {
  const { theme, } = useFela();
  return withoutWrapper ? (
    <TabElement {...props} elements={elements} />
  ) : (
    <ListView
      areasTemplate={'"he"'}
      gridGap="1rem"
      padding="0"
      {...(!props.title || theme.tabElementStyle.removeMarginTop ? { marginTop: 0, } : {})}
    >
      {props.title
        ? (
          <ListViewHeader
            isHorizontal
            title={props.title}
            miscStyles={theme.tabElementStyle.listViewHeaderMiscStyles}
          />
        )
        : null}
      <TabElement {...props} elements={elements} />
    </ListView>
  );
}
function WrappedTabsWithApollo({ withoutWrapper, elements, ...props }: Props): Node {
  const TabsToDisplay = elements.reduce((tabItems, tab) => {
    if (tab && tab.items) {
      tabItems.push(tab.items);
    }
    return tabItems;
  }, []);

  const { loadPriority, contentId, } = props;
  if (loadPriority === 'lazy') {
    return (
      <Query query={LAZY_TAB_ELEMENT} variables={{ input: { cid: contentId, }, }}>
        {({ loading, error, data, client, }) => {
          if (error || loading) return null;
          if (!data.lazyTabElements.elements) return null;
          return (
            <WrappedTabs
              elements={data.lazyTabElements.elements}
              withoutWrapper={withoutWrapper}
              {...props}
            />
          );
        }}
      </Query>
    );
  }
  return <WrappedTabs elements={TabsToDisplay} withoutWrapper={withoutWrapper} {...props} />;
}
export default WrappedTabsWithApollo;
