// @flow
/* eslint-disable react/prop-types, react/default-props-match-prop-types */
import type { ComponentPropResponsiveObject, StyleProps, } from '@haaretz/htz-css-tools';
import { FelaTheme, } from 'react-fela';
import * as React from 'react';

import type { ListExtraLinkType, } from '../../flowTypes/ListExtraLinkType';
import type { ListMarketingTeaserType, } from '../../flowTypes/ListMarketingTeaserType';
import type { ListBiActionType, } from '../../flowTypes/ListBiActionType';
import type { ColorPropType, } from '../../flowTypes/ColorPropType';
import GridItem from '../Grid/GridItem';
import ListViewHeader from './ListViewHeader';

type Props = {
  /** is the list header horizontal on large viewports */
  isHorizontal: boolean,
  /** Is the list's title padded at its inline start across breakpoints */
  hasTitlePadding: boolean,
  /**
   * The background color of the <ListViewHeader />.
   * Can be:
   *   - A `string` representing a named color.
   *   - A `tuple` of two `string`s, the first representing.
   *     a named color, and the second representing a variant
   *     of that named color.
   *   - An array of objects representing media queries, in
   *     the following structure:
   *     ```
   *     {
   *       from?: string,
   *       until?: string,
   *       misc?: string,
   *       value: string or tuple, as mentioned above,
   *     }
   *     ```
   */
  backgroundColor: ?ColorPropType,
  /** Overrides the default top-border and title text colors .
   * Can be:
   *   - A `string` representing a named color.
   *   - A `tuple` of two `string`s, the first representing.
   *     a named color, and the second representing a variant
   *     of that named color.
   */
  customColor: ?ColorPropType,
  /** A list of links to display. */
  extraLinks: ?(ListExtraLinkType[]),
  /** A commercial link and text. */
  commercialLinks: ?(ListExtraLinkType[]),
  /** A marketing tool, title and subTitle. */
  marketingTeaser: ?ListMarketingTeaserType,
  /** The List Title. */
  title: ?string,
  /**
   * A special property applying miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: ?StyleProps,
  /**
   * A special property applying miscellaneous CSS values that
   * trump all default values of the tile. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  titleMiscStyles: ?StyleProps,
  /** URL that leads to section of list. */
  url: ?string,
  /** Is it vertical list. */
  isVertical: boolean,
  isCommercial: boolean,
  /**
   * Useful for bi actions and events
   *
   * Should also be passed to underlying links, e.g.,
   * around the title and image
   */
  biAction: ?ListBiActionType,
  /**
   * A special property applying miscellaneous CSS values that
   * trump all default values of the inner Listview header.
   * Processed by [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  innerMiscStyles: ?StyleProps,
  /**
   * Should not be passed manually. Handled by the parent `<Grid>` component
   */
  gutter: ?number,
  /**
   * The width of the underlying `<GridItem />`.
   * The number passed should be (`width` / `columns`).
   * When the number passed to `width` is greater than `1`, it will be
   * used as an absolute width in rems.
   *
   * Can be set responsively.
   *
   * @example
   * // <StickyListViewHeader /> spans 25% (3 of 12 columns)
   * <StickyListViewHeader width={3 / 12} />
   *
   * // responsive settings:
   * <StickyListViewHeader
   *   width={[
   *     { from: 's', until: 'm', misc: 'landscape', value: 3 / 12 },
   *     { from: 'xl', value: 6 / 12 },
   *   ]}
   * />
   */
  width: ?number | ComponentPropResponsiveObject<number>[],
  /** Overrides the default top-border and title text colors .
   * Can be:
   *   - A `string` representing a named color.
   *   - A `tuple` of two `string`s, the first representing.
   *     a named color, and the second representing a variant
   *     of that named color.
   */
  customColor: ?ColorPropType,
};

StickyListViewHeader.defaultProps = {
  backgroundColor: [ { until: 's', value: [ 'neutral', '-10', ], }, ],
  customColor: null,
  biAction: null,
  commercialLinks: null,
  extraLinks: null,
  gutter: null,
  hasTitlePadding: false,
  innerMiscStyles: null,
  isCommercial: false,
  isHorizontal: false,
  isVertical: false,
  marketingTeaser: null,
  miscStyles: null,
  title: null,
  titleMiscStyles: null,
  url: null,
  width: null,
};

export default function StickyListViewHeader({
  gutter,
  innerMiscStyles,
  miscStyles,
  width,
  backgroundColor,
  customColor,
  biAction,
  commercialLinks,
  extraLinks,
  hasTitlePadding,
  isCommercial,
  isHorizontal,
  isVertical,
  marketingTeaser,
  title,
  titleMiscStyles,
  url,
}: Props): React.Node {
  return (
    <FelaTheme>
      {theme => (
        <GridItem
          gutter={gutter}
          stretchContent
          width={width}
          miscStyles={{
            position: [ { until: 's', value: 'sticky', }, ],
            top: [ { until: 's', value: '0', }, ],
            zIndex: [ { until: 's', value: theme.getZIndex('stickyListViewHeader'), }, ],
            ...(miscStyles || {}),
          }}
        >
          <ListViewHeader
            backgroundColor={backgroundColor}
            customColor={customColor}
            biAction={biAction}
            commercialLinks={commercialLinks}
            extraLinks={extraLinks}
            hasTitlePadding={hasTitlePadding}
            isCommercial={isCommercial}
            isHorizontal={isHorizontal}
            isVertical={isVertical}
            isSticky={false}
            marketingTeaser={marketingTeaser}
            title={title}
            titleMiscStyles={titleMiscStyles}
            url={url}
            miscStyles={innerMiscStyles}
          />
        </GridItem>
      )}
    </FelaTheme>
  );
}
