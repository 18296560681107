// @flow

import {
  type ComponentPropResponsiveObject,
  type StyleProps,
  parseStyleProps,
} from '@haaretz/htz-css-tools';
import { FelaComponent, } from 'react-fela';
import * as React from 'react';

import type { attrFlowType, } from '../../flowTypes/attrTypes';

import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import Grid from '../Grid/Grid';
import LayoutContainer from '../PageLayout/LayoutContainer';
import LayoutRow from '../PageLayout/LayoutRow';
import Section from '../AutoLevels/Section';

import isArray from '../../utils/isArray';
import isResponsiveOptions from '../../utils/isResponsiveOptions';

type PaddingTuple =
  | [number, ]
  | [number, number, ]
  | [number, number, number, ]
  | [number, number, number, number, ];

type PaddingValueType = number | PaddingTuple;

type PaddingType = PaddingValueType | Array<ComponentPropResponsiveObject<PaddingValueType>>;

type RowSpacingOpts = { amount: number, nUp?: number, };
type RowSpacingProp = RowSpacingOpts | ComponentPropResponsiveObject<RowSpacingOpts>[];

type SectionComponenType = {
  attrs: ?attrFlowType,
  children: ?React.Node,
  disableWrapper: boolean,
  /** The padding applied to the LayoutContainer */
  padding: ?PaddingType,
  sectionMiscStyles: ?StyleProps,
};

type ListViewWrapperPropTypes = SectionComponenType & {
  innerBackgroundColor:
    | ?string
    | [string, ]
    | [string, string, ]
    | ComponentPropResponsiveObject<string | [string, ] | [string, string, ]>[],
  outerBackgroundColor:
    | ?string
    | [string, ]
    | [string, string, ]
    | ComponentPropResponsiveObject<string | [string, ] | [string, string, ]>[],
  marginTop: number | ComponentPropResponsiveObject<number>[],
  miscStyles: ?StyleProps,
};

type ListViewPropTypes = ListViewWrapperPropTypes & {
  rowSpacing: ?RowSpacingProp,
  gridMiscStyles: ?StyleProps,
  gutter:
    | ?number
    | {
        onServerRender: number,
        queries: {
          from?: ?string,
          until?: ?string,
          misc?: ?string,
          type?: ?string,
          value: number,
        }[],
      },
};

const sectionDefaultProps = {
  attrs: null,
  children: null,
  disableWrapper: false,
  padding: null,
  sectionMiscStyles: null,
};

const defaultProps = {
  ...sectionDefaultProps,
  innerBackgroundColor: 'transparent',
  outerBackgroundColor: null,
  marginTop: [ { until: 's', value: 6, }, { from: 's', value: 8, }, ],
  miscStyles: null,
  padding: null,
};

ListView.defaultProps = {
  ...defaultProps,
  rowSpacing: null,
  gridMiscStyles: null,
  gutter: null,
};

export default function ListView({
  attrs,
  children,
  disableWrapper,
  gutter,
  marginTop,
  innerBackgroundColor,
  outerBackgroundColor,
  miscStyles,
  padding,
  rowSpacing,
  sectionMiscStyles,
  gridMiscStyles,
}: ListViewPropTypes): React.Node {
  return (
    <ErrorBoundary>
      <ListViewWrapper
        attrs={attrs}
        disableWrapper={disableWrapper}
        innerBackgroundColor={innerBackgroundColor}
        outerBackgroundColor={outerBackgroundColor}
        marginTop={marginTop}
        miscStyles={miscStyles}
        padding={padding}
        sectionMiscStyles={sectionMiscStyles}
      >
        <Grid gutter={gutter} rowSpacing={rowSpacing} miscStyles={gridMiscStyles}>
          {children}
        </Grid>
      </ListViewWrapper>
    </ErrorBoundary>
  );
}

ListViewWrapper.defaultProps = defaultProps;

function ListViewWrapper({
  attrs,
  children,
  disableWrapper,
  marginTop,
  innerBackgroundColor,
  outerBackgroundColor,
  miscStyles,
  padding,
  sectionMiscStyles,
}: ListViewWrapperPropTypes): React.Node {
  const hasMarginTop = marginTop != null;
  const hasPadding = padding != null;

  let sectionStyles;

  if (disableWrapper) {
    if (hasPadding || hasMarginTop || sectionMiscStyles) {
      sectionStyles = Object.assign(
        {},
        hasMarginTop ? { marginTop, } : null,
        padding != null ? setPadding(padding) : null,
        sectionMiscStyles || null
      );
    }
  }
  else if (sectionMiscStyles || hasMarginTop) {
    sectionStyles = hasMarginTop
      ? sectionMiscStyles
        ? Object.assign({}, { marginTop, }, sectionMiscStyles)
        : { marginTop, }
      : sectionMiscStyles;
  }

  const LayoutContainerStyle = disableWrapper
    ? null
    : padding != null
      ? miscStyles
        ? Object.assign(setPadding(padding), miscStyles)
        : setPadding(padding)
      : miscStyles;

  return disableWrapper ? (
    <SectionComponent sectionMiscStyles={sectionStyles} disableWrapper attrs={attrs}>
      {children}
    </SectionComponent>
  ) : (
    <SectionComponent attrs={attrs}>
      <LayoutRow
        attrs={attrs}
        tagName="section"
        namedBgc={outerBackgroundColor}
        miscStyles={sectionStyles}
      >
        <LayoutContainer namedBgc={innerBackgroundColor} miscStyles={LayoutContainerStyle}>
          {children}
        </LayoutContainer>
      </LayoutRow>
    </SectionComponent>
  );
}

SectionComponent.defaultProps = sectionDefaultProps;
function SectionComponent({
  children,
  sectionMiscStyles,
  disableWrapper,
  attrs,
}: SectionComponenType): React.Node {
  return disableWrapper && sectionMiscStyles ? (
    <FelaComponent
      style={({ theme, }) => ({
        extend: parseStyleProps(sectionMiscStyles, theme.mq, theme.type),
      })}
    >
      {({ className, }) => (
        <Section isFragment={false} className={className} {...attrs}>
          {children}
        </Section>
      )}
    </FelaComponent>
  ) : (
    <Section isFragment {...attrs}>
      {children}
    </Section>
  );
}

// /////////////////////////////////////////////////////////////////////
//                               UTILS                                //
// /////////////////////////////////////////////////////////////////////

type PaddingSideValue = string | Array<ComponentPropResponsiveObject<string>>;

type PaddingBpsObj = {
  paddingTop: Array<ComponentPropResponsiveObject<string>>,
  paddingInlineStart: Array<ComponentPropResponsiveObject<string>>,
  paddingBottom: Array<ComponentPropResponsiveObject<string>>,
  paddingInlineEnd: Array<ComponentPropResponsiveObject<string>>,
};

type PaddingCssObj = {
  paddingTop: PaddingSideValue,
  paddingInlineStart: PaddingSideValue,
  paddingBottom: PaddingSideValue,
  paddingInlineEnd: PaddingSideValue,
};

type PaddingValuesTuple = [
  string, // paddingTop
  string, // paddingInlineStart
  string, // paddingBottom
  string, // paddingInlineEnd
];

function getPaddingArray(paddingValues: PaddingValueType): PaddingTuple {
  return Array.isArray(paddingValues) ? paddingValues : [ paddingValues, ];
}

type PaddingPosition = 0 | 1 | 2 | 3;
function getPaddingSideValue(paddingValues: PaddingTuple, pos: PaddingPosition): string {
  /* $FlowFixMe: This is a flow bug:
   * https://github.com/facebook/flow/issues/4556 */
  if (pos <= paddingValues.length - 1) return `${paddingValues[pos]}rem`;
  const correspondingPos = Math.min(paddingValues.length, pos === 3 ? 1 : 0);

  /* $FlowFixMe: In this case, the result of the obove `Math.min` will
   * always be a correct number literal for `PaddingPosition` */
  return getPaddingSideValue(paddingValues, correspondingPos);
}

function getPaddingValues(paddingOpts: PaddingValueType): PaddingValuesTuple {
  const paddingArray = getPaddingArray(paddingOpts);

  /* $FlowFixMe: Flow treats this as an array of indefinite items,
   * that therefore cannot be verified to fit `PaddingValuesTuple`. */
  return [ 0, 1, 2, 3, ].map(pos => getPaddingSideValue(paddingArray, pos));
}

function paddingOptionsReducer(
  initialValue: ?PaddingBpsObj,
  bp: ComponentPropResponsiveObject<PaddingValueType>
): PaddingCssObj {
  const { from, until, value, } = bp;
  const [
    newPaddingTop,
    newPaddingInlineStart,
    newPaddingBottom,
    newPaddingInlineEnd,
  ] = getPaddingValues(value).map(sideValue => ({
    from,
    until,
    value: sideValue,
  }));

  const { paddingBottom = [], paddingInlineEnd = [], paddingInlineStart = [], paddingTop = [], } = initialValue || {};

  paddingBottom.push(newPaddingBottom);
  paddingInlineEnd.push(newPaddingInlineEnd);
  paddingInlineStart.push(newPaddingInlineStart);
  paddingTop.push(newPaddingTop);

  return {
    paddingBottom,
    paddingInlineEnd,
    paddingInlineStart,
    paddingTop,
  };
}

function setPadding(paddingOptions: PaddingType): ?PaddingCssObj {
  if (isArray(paddingOptions) && isResponsiveOptions(paddingOptions)) {
    if (paddingOptions.length === 0) return null;

    /* $FlowFixMe: Flow does not understand the refinement in `isResponsiveOptions` */
    return paddingOptions.reduce(paddingOptionsReducer, null);
  }

  const [
    paddingTop,
    paddingInlineStart,
    paddingBottom,
    paddingInlineEnd,

    /* $FlowFixMe: flow doesn't understand the refinement done by the `isResponsiveOptions`
     * predicate, and therefore cannot verify that `paddingOptions` is a `PaddingValueType` */
  ] = getPaddingValues(paddingOptions);
  return { paddingTop, paddingInlineStart, paddingBottom, paddingInlineEnd, };
}
