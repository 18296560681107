// @flow

import * as React from 'react';

import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import ListView from '../../../ListView/NewListView';
import ListViewHeader from '../../../ListViewHeader/ListViewHeader';
import Image from '../../../Image/Image';
import Section from '../../../AutoLevels/Section';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import setBiAction from '../../../../utils/setBiAction';

type SalPropsType = {
  list: ListDataType,
  isLazyloadImages: boolean,
  biAction: ?ListBiActionType,
  gaAction: ?() => void,
};

Sal.defaultProps = {
  biAction: null,
  gaAction: null,
  isLazyloadImages: true,
};

const areasList = [
  {
    from: 's',
    until: 'l',
    value: `
    "he he"
    "t1 t1"
    "t2 t3"
    "t4 t4"
    `,
  },
  {
    from: 'l',
    until: 'xl',
    value: `
    "he he he he he he he he he he he he"
    "t1 t1 t1 t1 t1 t1 t1 t2 t2 t2 t2 t2"
    "t3 t3 t3 t3 t3 t3 t4 t4 t4 t4 t4 t4"
    `,
  },
  {
    from: 'xl',
    value: `
    "he he he he"
    "t1 t2 t3 t4"
    `,
  },
];
const colTemplateList = [
  { until: 'l', value: '1fr 1fr', },
  { from: 'l', until: 'xl', value: 'repeat(12, 1fr)', },
  { from: 'xl', value: '4fr 3fr 2fr 3fr', },
];

export default function Sal({
  list,
  isLazyloadImages,
  biAction,
  gaAction,
}: SalPropsType): React.Node {
  const items = (list && list.items) || [];

  return (
    <ListView
      areasTemplate={areasList}
      colTemplate={colTemplateList}
      sectionMiscStyles={{ display: [ { until: 's', value: 'none', }, ], }}
      attrs={{
        'data-test': 'sal',
      }}
    >
      {/* Header */}
      <ListViewHeader isHorizontal {...list} width={1} miscStyles={{ marginBottom: '-3rem', }} />

      {/* Items */}
      <Section isFragment>
        {items.map((item, i) => (
          <SalTeaser
            key={item.contentId}
            data={item}
            onClick={setBiAction(i, item, biAction)}
            isLazyloadImages={isLazyloadImages}
            gridArea={`t${i + 1}`}
            showImage={i !== 2}
            hideImageUntilXl={i !== 0}
          />
        ))}
      </Section>
    </ListView>
  );
}

// /////////////////////////////////////////////////////////////////////
//                         Teaser Component                          //
// /////////////////////////////////////////////////////////////////////

type TeaserPropsType = {
  data: TeaserDataType,
  isLazyloadImages: boolean,
  onClick: ?() => void,
  gridArea: string,
  showImage: boolean,
  hideImageUntilXl: boolean,
};

SalTeaser.defaultProps = {
  isLazyloadImages: true,
  onClick: null,
};

const areasTeaser = (showImage, hideImageUntilXl) => (showImage ? [
  {
    until: 'xl',
    value: hideImageUntilXl
      ? `
      ".    .    ."
      ". content ."
      ` : `
      "media    .    ."
      "media content ."
      `,
  },
  {
    from: 'xl',
    value: `
    "media    .    ."
    "media content ."
    `,
  },
] : [
  {
    until: 'xl',
    value: `
    ".    .    ."
    ". content ."
    `,
  },
  {
    from: 'xl',
    value: `
    ". .    .    . ."
    ". . content . ."
    `,
  },
]);
const colTemplateTeaser = (showImage, hideImageUntilXl) => (showImage ? [
  ...(hideImageUntilXl
    ? [ { until: 'xl', value: '0 1fr 0', }, ]
    : [
      { from: 's', until: 'l', value: '28rem 1fr 0', },
      { from: 'l', until: 'xl', value: '2fr 5fr 0', },
    ]),
  { from: 'xl', value: '22rem 1fr 0', },
] : [
  { until: 'xl', value: '0 1fr 0', },
  { from: 'xl', value: '0 0 1fr 0 0', },
]);

function SalTeaser({
  data,
  isLazyloadImages,
  showImage,
  hideImageUntilXl,
  gridArea,
  onClick,
}: TeaserPropsType): React.Node {
  const image = data?.mobileImage || data?.image;

  return (
    <Teaser
      gridArea={gridArea}
      areasTemplate={areasTeaser(showImage, hideImageUntilXl)}
      colTemplate={colTemplateTeaser(showImage, hideImageUntilXl)}
      rowTemplate="0 1fr"
      gridGap="1rem"
    >
      {showImage && (
        <TeaserMedia
          data={data}
          onClick={onClick}
          miscStyles={hideImageUntilXl ? { display: [ { until: 'xl', value: 'none', }, ], } : null}
        >
          <Image
            lazyLoad={isLazyloadImages}
            image={image}
            imgOptions={{
              transforms: { width: '168', aspect: 'regular', },
            }}
          />
        </TeaserMedia>
      )}
      <TeaserHeader
        isGridItem
        onClick={onClick}
        typeScale={[ { until: 'xl', value: 0, }, { from: 'xl', value: -1, }, ]}
        {...data}
      />
    </Teaser>
  );
}
