// @flow
import React from 'react';
import type { StatelessFunctionalComponent, ChildrenArray, Node, ComponentType, ElementRef, } from 'react';
import { useFela, } from 'react-fela';

type Props = {
  render?: string | ComponentType<any>,
  children: ChildrenArray<Node> | Node,
  isActive?: boolean,
  index: number,
  controls: string,
  presentation: boolean,
  rule?: Object,
  setActiveTab?: number => void,
  onClick?: Function,
  tabRef?: { current: null | ElementRef<any>, } | (null | ElementRef<any>) => void,
}

/* eslint-disable react/prop-types */
const Tab: StatelessFunctionalComponent<Props> = ({
  render,
  children,
  isActive,
  index,
  controls,
  presentation,
  rule,
  setActiveTab,
  onClick,
  tabRef,
  ...restProps
}) => {
  const className = useFela({ isActive, index, ...restProps, }).css(rule);
  /* eslint-enable react/prop-types */
  const TabTag: string | ComponentType<any> = render || 'button';

  return presentation ? (
    <li role="presentation" className={className}>
      <TabTag
        {...(typeof TabTag !== 'string' ? { isActive, } : {})}
        onClick={() => {
          if (setActiveTab) setActiveTab(index);
          if (onClick) onClick();
        }}
        ref={tabRef}
        tabIndex={isActive ? '0' : '-1'}
        role="tab"
        aria-controls={controls}
        aria-selected={isActive}
      >
        {children}
      </TabTag>
    </li>
  ) : (
    <TabTag
      onClick={() => {
        if (setActiveTab) setActiveTab(index);
        if (onClick) onClick();
      }}
      ref={tabRef}
      className={className}
      tabIndex={isActive ? '0' : '-1'}
      role="tab"
      aria-controls={controls}
      aria-selected={isActive}
    >
      {children}
    </TabTag>
  );
};

export default Tab;
