// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

import type { ChildrenArray, Node, } from 'react';
import type { StyleProps, } from '@haaretz/htz-css-tools';

type Props = {
  render?: string,
  onKeyDown?: ?Function,
  children: ChildrenArray<Node> | Node,
  miscStyles?: ?StyleProps,
};

const style = ({ theme, miscStyles, }) => miscStyles
  && { extend: [ ...parseStyleProps(miscStyles, theme.mq, theme.type), ], };

function TabList(
  { render, children, miscStyles, onKeyDown, }: Props
): Node {
  const { css, } = useFela({ miscStyles, });
  const TabListTag: string = render || 'ul';
  return (
    <TabListTag className={css(style)} role="tablist" onKeyDown={onKeyDown}>
      {children}
    </TabListTag>
  );
}

TabList.defaultProps = {
  render: 'ul',
  onKeyDown: null,
  miscStyles: null,
};

export default TabList;
