// @flow
import isArray from './isArray';

function isObject(candidate: any): %checks {
  return candidate && typeof candidate === 'object';
}

function hasValue(candidate: any): boolean %checks {
  return candidate.value != null;
}

function hasBpOption(candidate: Object): boolean %checks {
  return (
    typeof candidate.from === 'string'
    || typeof candidate.until === 'string'
    || typeof candidate.misc === 'string'
    || typeof candidate.type === 'string'
  );
}

function isRespObject(candidate: any): boolean %checks {
  return isObject(candidate) && hasValue(candidate) && hasBpOption(candidate);
}

/*
 * Determine if `candidate` is a prop responsive options object.
 *
 * @param {*} candidate - The value to evaluate
 * @return {boolean}
 *
 * @private
 */
export default function isResponsiveOptions(candidate: any): boolean %checks {
  return (
    isArray(candidate)
    // Using `some` here is faulty, because it will return true even if some of
    // the items in the candidate array isn't a responsive. However, it is much
    // faster since it'll short circuit on the first item that is.
    // We should consider replacing `some` with `every` if this ever causes any
    // trouble that we decide isn't worth the perf boost.
    && candidate.some(isRespObject)
  );
}
