// @flow
import React, { useState, } from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

import type { Node, } from 'react';
import type { StyleProps, } from '@haaretz/htz-css-tools';

type Props = {
  activeTab: number,
  render: ?string,
  isFragment?: boolean,
  miscStyles?: ?StyleProps,
  children: ({
    activeTab: number,
    setActiveTab: number => void,
  }) => Node,
};

const style = ({ theme, miscStyles, }) => miscStyles && { extend: [ ...parseStyleProps(miscStyles, theme.mq, theme.type), ], };

Tabs.defaultProps = { render: null, miscStyles: null, isFragment: false, };
function Tabs({ render, miscStyles, isFragment, children, ...props }: Props): Node {
  const { css, } = useFela({ miscStyles, });
  const [ activeTab, setActiveTab, ] = useState(props.activeTab);
  const TabTag: string = render || 'div';
  const renderedChildren = children({ activeTab, setActiveTab, });
  return isFragment ? renderedChildren : <TabTag className={css(style)}>{renderedChildren}</TabTag>;
}

export default Tabs;
