// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, } from '@haaretz/htz-css-tools';

import type { StyleProps, } from '@haaretz/htz-css-tools';

import H from '../AutoLevels/H';
import HtzLink from '../HtzLink/HtzLink';
import LayoutContainer from '../PageLayout/LayoutContainer';
import LayoutRow from '../PageLayout/LayoutRow';
import Section from '../AutoLevels/Section';
import { useEventTracker, } from '../../utils/EventTracker';
import useHeaderNewsData from '../../hooks/Page/useHeaderNewsData';
import { useIsBot, } from '../../hooks/useIsBot';
import WebViewExclude from '../WebViewExclude/WebViewExclude';

export type Props = {
  miscStyles?: ?StyleProps,
};

TopNews.defaultProps = {
  miscStyles: null,
};

function TopNews({ miscStyles, }: Props): React.Node {
  const { css, theme, } = useFela();
  const { biAction, } = useEventTracker();
  const { isBot, } = useIsBot();
  const headerNewsData = useHeaderNewsData('topNews');

  const sectionClasses = css({
    fontFamily: theme.topNewsStyle.fontFamily,
    paddingInlineEnd: '4rem',
    paddingInlineStart: '4rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    borderTop: theme.topNewsStyle.sectionClasses({ theme, }).border,
    borderBottom: theme.topNewsStyle.sectionClasses({ theme, }).border,
    paddingTop: theme.topNewsStyle.sectionClasses({ theme, }).paddingTop,
    paddingBottom: theme.topNewsStyle.sectionClasses({ theme, }).paddingBottom,
    extend: [
      theme.mq({ until: 'l', }, { display: isBot ? 'block' : 'none', }),
      theme.type(-1, { untilBp: 'xl', lines: 4, }),
      theme.type(-2, { fromBp: 'xl', }),
      ...(miscStyles ? parseStyleProps(miscStyles, theme.mq, theme.type) : []),
    ],
  });
  const headerClasses = css({
    color: theme.color('tertiary'),
    display: 'inline',
    fontWeight: '700',
    paddingInlineEnd: '1rem',
  });

  return (
    <WebViewExclude>
      <LayoutRow miscStyles={{ display: [ { until: 'l', value: isBot ? 'block' : 'none', }, ], }}>
        <LayoutContainer
          tagname="section"
          namedBgc="white"
          miscStyles={{
            paddingTop: [ { from: 'l', value: '5rem', }, ],
          }}
        >
          <Section tagName="div" data-test="topNews" className={sectionClasses}>
            <H className={headerClasses} offset={2}>
              {theme.topNewsStyle.href ? (
                <HtzLink href={theme.topNewsStyle.href}>
                  {headerNewsData?.name}
                </HtzLink>
              ) : (
              headerNewsData?.name
              )}
            </H>

            <ul className={css({ display: 'inline-block', })}>
              {headerNewsData
              && headerNewsData.items?.map((link, idx) => {
                // extract link article id from article url
                // TODO: create and import as utils function when data check is over.
                const articleIdPattern = /^.*(1\.\d+){1}$/;
                const linkArticle = link && articleIdPattern.exec(link.url);
                const linkArticleId = Array.isArray(linkArticle)
                  ? linkArticle[1]
                  : undefined;

                const isLast = idx === headerNewsData?.items?.length - 1;
                const isHighlighted = idx >= (headerNewsData?.items?.length || 999) - (headerNewsData?.highlightedItemsCount || 0);

                const hoverFocusStyle = {
                  textDecoration: 'underline',
                  textDecorationColor: theme.color('topNews', 'hoverUnderline'),
                };
                return (
                  <li
                    key={link.title}
                    className={css({
                      display: 'inline-block',
                      color: theme.color(
                        ...(isHighlighted
                          ? [ 'topNews', 'lastItem', ]
                          : [ 'topNews', 'item', ])
                      ),
                      ...(isLast
                        ? {}
                        : {
                          '&:after': {
                            content: '"|"',
                            paddingInlineEnd: '1rem',
                            paddingInlineStart: '1rem',
                          },
                        }),
                    })}
                  >
                    <HtzLink
                      href={link.url}
                      className={css({
                        fontWeight: '700',
                        '&:hover': hoverFocusStyle,
                        '&:focus': hoverFocusStyle,
                      })}
                      onClick={() => {
                        biAction({
                          actionCode: 109,
                          campaignDetails: link.title,
                          NoInList: idx + 1,
                          // TODO: get item content name from bs
                          // nextArticleTeaserId: link.contentId,
                          ArticleId: linkArticleId,
                          ViewName: 'In the News',
                        });
                      }}
                    >
                      {link.title}
                    </HtzLink>
                  </li>
                );
              })}
            </ul>
          </Section>
        </LayoutContainer>
      </LayoutRow>
    </WebViewExclude>
  );
}

export default TopNews;
